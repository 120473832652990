import React from 'react'

function Flash(props) {
  if(props.flash && props.flash.message && props.flash.type) {
    const message = props.flash.message
    const success = props.flash.type == 'notice'
    const showIcon = !Object.keys(props.flash).includes('hide_icon')

    const attemptClear = (_e) => {
      if(props.clearFlash) {
        props.clearFlash()
      }
    }
  
    return(
      <div className={`flash ${showIcon ? '' : 'flash--hide-icon'}`} onClick={attemptClear}>
        <div className={`flash__contents ${success ? 'notice' : 'error'}`}>
          <div className='flash__message'>{ message }</div>
          { showIcon &&
            <div className='flash__icon'>
              { success &&
                <i className='fa fa-lg fa-regular fa-circle-check'></i>
              }
              { !success &&
                <i className="fa fa-lg fa-solid fa-triangle-exclamation"></i>
              }
            </div>
          }
        </div>
      </div>
    )
  }
  else {
    return null
  }
}

export default Flash
