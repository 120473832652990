import React from 'react'

import Photo from '../Photo'
import AspectGroupMetrics from './AspectGroupMetrics'

import ApiService from '../../services/ApiService'
import ClickHelpers from '../../helpers/ClickHelpers'

class PhotosContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = { selectedPhotos: [] }
  }

  _selectedPhotosAttribute = (attribute) => {
    return this.state.selectedPhotos.map((photo) => photo[attribute])
  }

  _selectedPhotoObject = (token) => {
    return { token, aspectGroup: this._photoAspectGroup(token) }
  }

  _photoAspectGroup = (token) => {
    return ClickHelpers.photoElementFromToken(token).getAttribute('data-aspect-group')
  }

  _matchingAspectGroups = (sourceToken, targetToken) => {
    const sourceSelectedPhoto = this.state.selectedPhotos.find((el) => el.token === sourceToken)

    // load aspect group from state because photo may not be retrievable in the DOM (e.g. photos between different pagination groups)
    const sourcePhotoAspectGroup = sourceSelectedPhoto.aspectGroup
    // this aspect group doesn't yet exist in selectedPhotos state, but will always be retrievable from the DOM (we just clicked it)
    const targetPhotoAspectGroup = this._photoAspectGroup(targetToken)

    return sourcePhotoAspectGroup === targetPhotoAspectGroup
  }

  _handleServiceResponse = (response) => {
    if(response['success']) {
      // replace just the photo data for that aspect group
      // (it's already been updated and re-ordered in the back-end)
      this.setState({ selectedPhotos: [] })

      this.props.reloadPhotoData(response['data'])
    }
    else {
      this.setState({ selectedPhotos: [] })
    }
  }

  photoClickHandler = (ev) => {
    const currentToken = ev.target.getAttribute('data-token')
    let selectedPhotos = this.state.selectedPhotos
    const selectedPhotoTokens = this._selectedPhotosAttribute('token')

    const potentialSelectedPhotoToAdd = this._selectedPhotoObject(currentToken)

    // empty, just add it
    if(selectedPhotos.length === 0) {
      selectedPhotos.push(potentialSelectedPhotoToAdd)
    }
    else if(selectedPhotoTokens.includes(currentToken)) {
      // clear selections
      selectedPhotos = []
    }
    else if(selectedPhotos.length === 2) {
      // start selections again
      selectedPhotos = [potentialSelectedPhotoToAdd]
    }
    else {
      // check aspect groups here too, and if they're the same just add the token
      if(this._matchingAspectGroups(selectedPhotos[0].token, currentToken)) {
        selectedPhotos.push(potentialSelectedPhotoToAdd)
      }
      else {
        // start selections again
        selectedPhotos = [potentialSelectedPhotoToAdd]
      }
    }

    this.setState({ selectedPhotos })
  }

  photoTokenFromWrapper = (element) => {
    return element.closest('.photo__wrapper').getAttribute('data-token')
  }

  hidePhoto = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.hidePhoto(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  showPhoto = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)
    
    ApiService.showPhoto(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  markForReplacement = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.markForReplacement(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  unmarkForReplacement = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.unmarkForReplacement(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  markForDownload = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.markForDownload(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  unmarkForDownload = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.unmarkForDownload(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  markForDeletion = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.markForDeletion(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  unmarkForDeletion = (ev) => {
    const currentToken = this.photoTokenFromWrapper(ev.target)

    ApiService.unmarkForDeletion(currentToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  swapPhotos = (_ev) => {
    const [sourceToken, targetToken] = this._selectedPhotosAttribute('token')
    
    this.state.selectedPhotos.map((el) => el.token)

    if(sourceToken === null || targetToken === null) {
      return null
    }

    const [sourcePhotoAspectGroup, targetPhotoAspectGroup] = this._selectedPhotosAttribute('aspectGroup')


    if(sourcePhotoAspectGroup !== targetPhotoAspectGroup) {
      this.setState({ selectedPhotos: [] })

      return null
    }

    ApiService.swapAspectGroupIndexes(sourceToken, targetToken, this.props.showHiddenPhotos, this.props.excludeSeriesPhotos, this.props.excludeBRollPhotos)
      .then((serviceResponse) => {
        this._handleServiceResponse(serviceResponse)
      })
  }

  _randomIndex = () => {
    return Math.floor(Math.random() * Math.floor(1000))
  }

  render() {
    if(this.props.photoData) {
      const photoData = this.props.photoData
      const photoAspectGroups = Object.keys(photoData)
      let aspectGroupMetricsRendered = photoAspectGroups.reduce((groups, group) => ({ ...groups, [group]: false }), {})

      return(
        <React.Fragment>
          {
            photoAspectGroups.map((aspectGroup, parentIndex) => {
              let aspectGroupPhotoData = photoData[aspectGroup]
              let selectedPhotoTokens = this._selectedPhotosAttribute('token')

              return(
                <div className='photos-container container' key={parentIndex}>
                  {
                    aspectGroupPhotoData.map((photo, photoIndex) => {
                      const token = photo['token']
                      const selected = selectedPhotoTokens.includes(token)
                      
                      // only show the swap button if the selected photo is the last selected
                      const swappable = selected ? (selectedPhotoTokens.indexOf(token) === 1) : false
                      const componentIndexSuffix = `${photo['basename']}-${aspectGroup}-${photoIndex}`
                      
                      // logic to make sure is rendered just once, without associating it with a photo index (which could be in show/hide state)
                      const renderGroupMetrics = !aspectGroupMetricsRendered[aspectGroup]
                      aspectGroupMetricsRendered[aspectGroup] ||= true

                      return(
                        <React.Fragment key={`photo-photo-wrapper-${parentIndex}-${photoIndex}`}>
                          <AspectGroupMetrics
                            key={`aspect-group-metric-${componentIndexSuffix}`}
                            isShowing={renderGroupMetrics}
                            aspectGroup={aspectGroup}
                            aspectGroupPhotoData={aspectGroupPhotoData}
                          />

                          <Photo
                            key={`photo-${componentIndexSuffix}`}
                            isShowing={true}
                            usageType={'admin-dreams-edit'}
                            photo={photo}
                            aspectGroup={aspectGroup}
                            aspectGroupIndex={photo['aspect_group_index']}
                            selected={selected}
                            swappable={swappable}
                            isHidden={photo['hidden']}
                            isMarkedForReplacement={photo['marked_for_replacement']}
                            isMarkedForDownload={photo['marked_for_download']}
                            isMarkedForDeletion={photo['marked_for_deletion']}
                            photoClickHandler={this.photoClickHandler}
                            swapPhotoButtonClickHandler={this.swapPhotos}
                            hidePhotoButtonClickHandler={this.hidePhoto}
                            showPhotoButtonClickHandler={this.showPhoto}
                            markForReplacementClickHandler={this.markForReplacement}
                            unmarkForReplacementClickHandler={this.unmarkForReplacement}
                            markForDownloadClickHander={this.markForDownload}
                            unmarkForDownloadClickHander={this.unmarkForDownload}
                            markForDeletionClickHandler={this.markForDeletion}
                            unmarkForDeletionClickHandler={this.unmarkForDeletion}
                          />
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
}

export default PhotosContainer
