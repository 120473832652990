import React from 'react'
import Photo from './Photo'
import SiteLinks from './SiteLinks'
import Flash from './Flash';
import FlashHelper from '../helpers/FlashHelper';

class PhotosContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstPhotoLoaded: false,
      flash: null
    }
  }

  componentDidMount() {
    FlashHelper.initialise(this.currentFlashState, this.clearFlashState)
    const flash = FlashHelper.loadInitialFlash()

    this.setState({ flash })
  }

  currentFlashState = () => {
    return this.state ? this.state.flash : {}
  }

  clearFlashState = () => {
    this.setState({ flash: null })
  }

  // when at least one photo is loaded, the parent component can render the pagination
  setPhotoLoaded = () => {
    if(!this.state.firstPhotoLoaded) {
      this.setState({ firstPhotoLoaded: true });
      this.props.showPagination();
    }
  }

  render() {
    if(this.props.photoData) {
      const photoData = this.props.photoData;
      const photoAspectGroups = Object.keys(photoData)

      return(
        <React.Fragment>
          <Flash
            flash={this.state.flash}
            clearFlash={this.clearFlashState}
          />
          {
            photoAspectGroups.map((aspectGroup, parentIndex) => {
              const aspectGroupPhotoData = photoData[aspectGroup];

              return(
                <div className='photos-container container' key={parentIndex}>
                  {
                    aspectGroupPhotoData.map((photo, photoIndex) => {
                      let componentIndexSuffix = `site-link-${photo['basename']}-${aspectGroup}-${photoIndex}`;

                      return(
                        <React.Fragment key={`photo-photo-wrapper-${componentIndexSuffix}`}>
                          <SiteLinks
                            key={`site-link-${componentIndexSuffix}`}
                            isShowing={parentIndex === 0 && photoIndex === 0}
                            position={'right'}
                          />
                          
                          <Photo
                            key={`photo-${componentIndexSuffix}`}
                            isShowing={true}
                            lazyLoad={true}
                            usageType={'show'}
                            photo={photo}
                            aspectGroup={aspectGroup}
                            setPhotoLoaded={this.setPhotoLoaded}
                          />
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </React.Fragment>
      );
    }
    else {
      return null;
    }
  }
}

export default PhotosContainer;
