import React from 'react'
// import ApiService from '../services/ApiService'
import PaginationHelper from '../helpers/PaginationHelper'

import ApiService from '../services/ApiService'
import PhotosHelper from '../helpers/PhotosHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import LocalStorageContext from './contexts/LocalStorageContext'
import UrlParamHelper from '../helpers/UrlParamHelper'

import Navbar from './Navbar'
import SocialLinksModal from './modals/SocialLinksModal'
import PrintsListModal from './modals/PrintsListModal'
import PhotosContainer from './PhotosContainer'
import Pagination from './Pagination'
import Footer from './Footer'

class Home extends React.Component {
  constructor(props) {
    super(props)

    // photoData and currentCartPhotos will be set async
    this.state = {
      photoData: {},
      allPhotosData: [],
      currentPage: "1",
      showPagination: false,
      currentCartPhotos: [],
      currentCartPhotoTokens: []
    }
  }

  loadUrlParams = () => {
    const urlParams = UrlParamHelper.loadParams()

    // all possible URL params that this page handles
    const page = urlParams.get('page')
    const clearCurrentCart = urlParams.get('clear_current_cart')

    if(page && (/^[-+]?\d+$/.test(page))) {
      this.setState({ currentPage: page }, this.storeCurrentPage)
    }

    if(clearCurrentCart && clearCurrentCart === 'true') {
      // tried to navigate to order checkout when the order was missing, so clear param
      LocalStorageHelper.clearCurrentCart()
      
      UrlParamHelper.clearUrlParam(urlParams, 'clear_current_cart')
    }
  }

  storeCurrentPage = () => {
    // This is so when clicking a photo to go to show page and then hitting back, browser will go back to the correct page
    const currentState = history.state || {}
    const newURL = `/photos/?page=${this.state.currentPage}`

    // Use pushState to update the URL without triggering a page reload
    history.pushState({ ...currentState, page: this.state.currentPage }, document.title, newURL)
  }

  disconnectPerformanceObserver = () => {
    if (this.performanceObserver) {
      this.performanceObserver.disconnect()
    }
  }

  // to fix issue where if navigating back on mobile page is not reloaded properly
  // e.g. going back from photo show page after adding item to cart and new cart item won't show up when navigating back

  initialisePerformanceObserver = () => {
    this.performanceObserver = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.type === 'back_forward') {
          PhotosHelper.setCurrentCartPhotosFromLocalStorage(this)
          // otherwise it runs multiple times
          this.disconnectPerformanceObserver()
        }
      })
    })
    
    this.performanceObserver.observe({ type: 'navigation', buffered: true })
  }

  componentDidMount() {
 

    this.loadUrlParams()

    PhotosHelper.loadPhotos(this)
  }

  componentDidUpdate(_prevProps, prevState) {
    if(prevState.currentPage !== this.state.currentPage) {
      this.storeCurrentPage()
    }
  }

  setCurrentCartPhotosFromTokens = (currentCartPhotoTokens) => {
    PhotosHelper.setCurrentCartPhotosFromTokens(this, currentCartPhotoTokens)
  }

  showPagination = () => {
    const showPagination = this._allPages().length > 1

    this.setState({ showPagination })
  }

  // full range of possible pages
  _allPages = () => {
    return Object.keys(this.state.photoData)
  }

  changePage = (page) => {
    let newPage = PaginationHelper.newPage(page, this.state.currentPage, this._allPages())

    if(newPage) {
      this.setState({ currentPage: newPage })
    }
  }

  render() {
    const currentCartPhotos = this.state.currentCartPhotos

    return(
      <LocalStorageContext.Provider
        value={{
          currentOrderToken: LocalStorageHelper.currentOrderToken(),
          currentCartPhotos,
          currentCartPhotoTokens: this.state.currentCartPhotoTokens,
          setCurrentCartPhotosFromTokens: this.setCurrentCartPhotosFromTokens,
        }}
      >

        <Navbar title='keenedreams'/>

        <PrintsListModal
          currentCartPhotos={this.state.currentCartPhotos}
          currentCartPhotoTokens={this.state.currentCartPhotoTokens}
          setCurrentCartPhotosFromTokens={this.setCurrentCartPhotosFromTokens}
        />

        <PhotosContainer
          photoData={this.state.photoData[this.state.currentPage]}
          showPagination={this.showPagination}
        />

        <Pagination
          isShowing={this.state.showPagination}
          currentPage={this.state.currentPage}
          pages={this._allPages()}
          changePage={this.changePage}
        />

        <SocialLinksModal />

        <Footer currentPage={'home'} />
      </LocalStorageContext.Provider>
    )
  }
}

export default Home